import React, { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import SearchInputV2 from "../SearchInputV2/SearchInputV2"
import MultiSelectDropdown from "../MultiSelectDropdown/MultiSelectDropdown"
import { editTicketStatus as editTicketStatusNetworkReliability } from "../../pages/NetworkReliability/NetworkReliabilitySlice"
import { useLazyGetChargerDetailsQuery, useUpdateChargerDetailsMutation } from "../../pages/Common/commonService"
import { useEditLogisticsTicketStatusMutation } from "../../pages/Logistics/LogisticsServices"
import { TStore } from "../../store/Store"

import styles from "./ChargerDetailsModal.module.scss"

const chargerDetailsContext: any = React.createContext(null)

const ChargerDetailsProvider = ({ children }: any) => {
    const { makeData = [] } = useSelector((state: any) => state?.common)
    const { logisticsDealDetailData } = useSelector((state: TStore) => state?.Logistics)
    const [editable, setEditable] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [chargerData, setChargerData]: any = useState([])
    const [rowData, setRowData]: any = useState([])
    const [rowDataEditable, setRowDataEditable] = useState([])
    const [headerData, setHeaderData]: any = useState([])
    const [ticketData, setTicketData]: any = useState({ id: "", type: "" })
    const [ticketputRequired, setTicketPutRequired] = useState(false)
    const [serviceCommentsWarningOpen, setServiceCommentsWarningOpen] = useState(false)
    const [selectedVendor, setSelectedVendor] = useState({ id: "", title: "" })
    const [showError, setShowError] = useState(false)
    const [chargerTested, setChargerTested] = useState(false)
    const [selectedTab, setSelectedTab] = useState(0)
    const [formData, setFormData]: any = useState(null)

    const deal_type = logisticsDealDetailData?.data?.project_obj?.deal_type
    const ticket_status = logisticsDealDetailData?.data?.ticket_details?.status

    const tabRef = useRef<HTMLUListElement>()

    const [editLogisitcsTicketStatus, { isLoading: isEditTicketStatusLoading, isSuccess: isEditTicketStatusSuccess }] =
        useEditLogisticsTicketStatusMutation()
    const [getChargerDetails, { data: chargerDetailsData, isSuccess }] = useLazyGetChargerDetailsQuery()
    const [updateChargerDeatils] = useUpdateChargerDetailsMutation()

    const resetStates = () => {
        setEditable(false)
        setOpenModal(false)
        setChargerData([])
        setRowData([])
        setRowDataEditable([])
        setTicketData({ id: "", type: "" })
        setTicketPutRequired(false)
        setFormData(null)
        setSelectedTab(0)
    }

    const chargerDataSetter = (index: number, key: string, value: any) => {
        let clone: any = [...chargerData]
        clone[index] = { ...chargerData[index] }
        if (key === "make") {
            clone[index][key] = value?.title
        } else {
            clone[index][key] = value
        }
        setChargerData(clone)
    }

    const getHeading = () => {
        if (ticketData.type === "installation") return "Operational Charger Details"
        return ticketData?.type === "tech_support" ||
            ticketData?.type === "finance" ||
            ticketData?.type === "projects" ||
            ticketData?.type === "demolitions"
            ? "Charger Details"
            : editable
            ? "(2/2) Enter Charger Details"
            : "(1/2) Enter Charger Details"
    }

    useEffect(() => {
        if (!openModal) {
            setEditable(false)
            setChargerData([])
            setRowData([])
            setRowDataEditable([])
            setTicketData({ id: "", type: "" })
            setTicketPutRequired(false)
            setFormData(null)
        }
        if (openModal) getChargerDetails({ ticket_id: ticketData?.id, ticket_type: ticketData?.type })
    }, [openModal])

    useEffect(() => {
        if (openModal) {
            setChargerData(chargerDetailsData)
        }
    }, [chargerDetailsData, selectedTab, openModal])

    useEffect(() => {
        if (openModal) {
            const rowData = chargerData?.map((data: any, index: number) => {
                const rowdata: any = {
                    "S.No": { value: index + 1 },
                    "Charger Name": { value: data?.charger_name },
                    "Charger Type": { value: data?.charger_type ?? "-" },
                    "Operational Power Rating (kWh)": { value: data?.operational_power_rating },
                    "Serial Number/CIN": { value: data?.serial_cin },
                    "Charger SKU": { value: data?.charger_sku },
                    Branding: {
                        value: `${data?.branding || ""}${data?.branding && data?.branding_sku ? ", " : ""}${
                            data?.branding_sku || ""
                        }`,
                    },
                    OEM: { value: data?.oem },
                    Make: { value: data?.make },
                    "Type Of Connectors": { value: data?.connector_type },
                    "No. Of Connectors": { value: data?.connector_no },
                    "Sim Information": {
                        value: `${data?.is_sim_required ? "Yes" : "No"}${
                            data?.is_sim_required && data?.sim_uuid ? ", " : ""
                        }${data?.sim_uuid || ""}`,
                    },
                    "Charging Price": { value: data?.charging_price },
                    "Insurance Required": {
                        value: data?.insurance_required === 1 ? "Yes" : data?.insurance_required === 0 ? "No" : "-",
                    },
                    "Insurance Procured": {
                        value: data?.insurance_procured === 1 ? "Yes" : data?.insurance_procured === 0 ? "No" : "-",
                    },
                    "Insurance Status": {
                        value:
                            data?.insurance_required === 1 ? (
                                data?.insurance_procured === 1 ? (
                                    <span className={styles.insurance_text}>Procured</span>
                                ) : (
                                    <span className={`${styles.insurance_text} ${styles.not_proc}`}>Not Procured</span>
                                )
                            ) : (
                                <span className={`${styles.insurance_text} ${styles.not_req}`}>Not Required</span>
                            ),
                    },
                    "Warranty Duration": { value: data?.warranty_duration ? data?.warranty_duration : "-" },
                    "Warranty Comments": { value: data?.warranty_comment ? data?.warranty_comment : "-" },
                    "AMC Purchased": { value: data?.amc_purchased ? "Yes" : "No" },
                    "AMC Status": {
                        value: data?.amc_status === 1 ? "Active" : data?.amc_status === 0 ? "Expired" : "Inactive",
                    },
                    "Power Rating": { value: data?.connector_rating },
                    "OCPP ID": { value: data?.ocpp_id ?? "-" },
                    "Date Of Onboarding": { value: data?.onboarding_date },
                    "AMC Information": { value: data?.amc_info ? data?.amc_info : "-" },
                    "Service Scope": { value: data?.service_scope ? data?.service_scope : "-" },
                    "Service Comments": { value: data?.service_comment ? data?.service_comment : "-" },
                    "Onboarding Status": {
                        value: data?.onboarding_flag ? (data?.onboarding_status ? data?.onboarding_status : "-") : "-",
                    },
                    "Commissioning Status": {
                        value: (
                            <input
                                type="checkbox"
                                defaultChecked={data.commissioning_status === 1}
                                onChange={e =>
                                    chargerDataSetter(
                                        index,
                                        "commissioning_status",
                                        e.target.checked
                                            ? 1
                                            : chargerDetailsData?.[index].commissioning_status === 1
                                            ? 0
                                            : chargerDetailsData?.[index].commissioning_status
                                    )
                                }
                                style={{ marginRight: 3, marginBottom: 3, cursor: "pointer" }}
                                disabled={true}
                            />
                        ),
                    },
                    "Testing Status": {
                        value: (
                            <input
                                type="checkbox"
                                value={data.testing_status}
                                checked={data.testing_status}
                                onChange={e => chargerDataSetter(index, "testing_status", e.target.checked)}
                                style={{ marginRight: 3, marginBottom: 3, cursor: "pointer" }}
                                disabled={true}
                            />
                        ),
                    },
                }

                const rowdataEditable: any = {
                    "S.No": { value: index + 1 },
                    "Charger Name": { value: data?.charger_name },
                    "Charger Type": { value: data?.charger_type ?? "-" },
                    "Operational Power Rating (kWh)": { value: data?.operational_power_rating },
                    "Serial Number/CIN": {
                        value:
                            (ticketData?.type === "finance" && !data?.freeze_cin) ||
                            (ticketData?.type === "logistics" && deal_type?.toLowerCase() === "cpo") ? (
                                <SearchInputV2
                                    fontsize="12px"
                                    placeholder={"Enter Value"}
                                    searchIcon={false}
                                    onSearch={(value: string) => {
                                        chargerDataSetter(index, "serial_cin", value)
                                    }}
                                    value={data?.serial_cin}
                                    // type="number"
                                    error={!data?.serial_cin}
                                    showError={showError}
                                    height="24px"
                                />
                            ) : (
                                data?.serial_cin
                            ),
                    },
                    "Charger SKU": { value: data?.charger_sku },
                    Branding: {
                        value: `${data?.branding || ""}${data?.branding && data?.branding_sku ? ", " : ""}${
                            data?.branding_sku || ""
                        }`,
                    },
                    OEM: { value: data?.oem },
                    Make: {
                        value: (
                            <MultiSelectDropdown
                                fontsize="12px"
                                title="Select Type"
                                options={
                                    makeData?.length
                                        ? makeData?.map((maker: any, index: number) => {
                                              return { id: maker?.id, title: maker?.name }
                                          })
                                        : []
                                }
                                id="make"
                                onChange={() => {}}
                                selectedOptions={
                                    data?.make?.length
                                        ? makeData
                                              ?.filter((maker: any) => maker?.name === data?.make)
                                              ?.map((maker: any, index: number) => {
                                                  return { id: maker?.id, title: maker?.name }
                                              })
                                        : []
                                }
                                selectionType={"single"}
                                onSelect={(value: any) => {
                                    chargerDataSetter(index, "make", value)
                                }}
                                height="24px"
                                dropdownHeight="60px"
                                width="80px"
                                optionMargin={"0px"}
                                dropdownPadding={"0px"}
                                error={!data?.make?.length}
                                showError={showError}
                            />
                        ),
                    },
                    "Type Of Connectors": { value: data?.connector_type },
                    "No. Of Connectors": { value: data?.connector_no },
                    "Sim Information": {
                        value: `${data?.is_sim_required ? "Yes" : "No"}${
                            data?.is_sim_required && data?.sim_uuid ? ", " : ""
                        }${data?.sim_uuid || ""}`,
                    },
                    "Charging Price": { value: data?.charging_price },
                    "Insurance Required": {
                        value: data?.insurance_required === 1 ? "Yes" : data?.insurance_required === 0 ? "No" : "-",
                    },
                    "Insurance Procured": {
                        value:
                            data?.insurance_required &&
                            ticketData?.type === "finance" &&
                            data?.insurance_required === 1 &&
                            !data?.freeze_insurance ? (
                                <MultiSelectDropdown
                                    fontsize="12px"
                                    title="Insurance Procured"
                                    options={[
                                        { id: 0, title: "No" },
                                        { id: 1, title: "Yes" },
                                    ]}
                                    id="make"
                                    onChange={() => {}}
                                    selectedOptions={[
                                        { id: 0, title: "No" },
                                        { id: 1, title: "Yes" },
                                    ]?.filter(i => i.id === data?.insurance_procured)}
                                    selectionType={"single"}
                                    onSelect={(value: any) => {
                                        chargerDataSetter(index, "insurance_procured", value?.id)
                                    }}
                                    height="24px"
                                    dropdownHeight="60px"
                                    width="80px"
                                    optionMargin={"0px"}
                                    dropdownPadding={"0px"}
                                    error={!data?.insurance_procured}
                                    showError={showError}
                                />
                            ) : data?.insurance_procured === 1 ? (
                                "Yes"
                            ) : (
                                "No"
                            ),
                    },
                    "Insurance Status": {
                        value:
                            data?.insurance_required === 1 ? (
                                data?.insurance_procured === 1 ? (
                                    <span className={styles.insurance_text}>Procured</span>
                                ) : (
                                    <span className={`${styles.insurance_text} ${styles.not_proc}`}>Not Procured</span>
                                )
                            ) : (
                                <span className={`${styles.insurance_text} ${styles.not_req}`}>Not Required</span>
                            ),
                    },
                    "Warranty Duration": { value: data?.warranty_duration ? data?.warranty_duration : "-" },
                    "Warranty Comments": { value: data?.warranty_comment ? data?.warranty_comment : "-" },
                    "AMC Purchased": { value: data?.amc_purchased ? "Yes" : "No" },
                    "AMC Status": { value: data?.amc_status },
                    "Power Rating": { value: data?.connector_rating },
                    "OCPP ID": { value: data?.ocpp_id },
                    "Date Of Onboarding": { value: data?.onboarding_date },
                    "AMC Information": { value: data?.amc_info ? data?.amc_info : "-" },
                    "Service Scope": { value: data?.service_scope ? data?.service_scope : "-" },
                    "Service Comments": {
                        value: (
                            <SearchInputV2
                                fontsize="12px"
                                placeholder={"Enter Comments"}
                                searchIcon={false}
                                onSearch={(value: string) => {
                                    chargerDataSetter(index, "service_comment", value)
                                }}
                                value={data?.service_comment}
                                height="24px"
                                width="150px"
                            />
                        ),
                    },
                    "Onboarding Status": { value: data?.onboarding_status ? data?.onboarding_status : "-" },
                    "Commissioning Status": {
                        value: (
                            <input
                                type="checkbox"
                                defaultChecked={data.commissioning_status === 1}
                                onChange={e =>
                                    chargerDataSetter(
                                        index,
                                        "commissioning_status",
                                        e.target.checked
                                            ? 1
                                            : chargerDetailsData?.[index].commissioning_status === 1
                                            ? 0
                                            : chargerDetailsData?.[index].commissioning_status
                                    )
                                }
                                style={{ marginRight: 3, marginBottom: 3, cursor: "pointer" }}
                                disabled={data?.freeze_commissioning}
                            />
                        ),
                    },
                    "Testing Status": {
                        value: (
                            <input
                                type="checkbox"
                                value={data.testing_status}
                                checked={data.testing_status}
                                onChange={e => chargerDataSetter(index, "testing_status", e.target.checked)}
                                style={{ marginRight: 3, marginBottom: 3, cursor: "pointer" }}
                                disabled={data?.freeze_testing_status}
                            />
                        ),
                    },
                }
                if (ticketData?.type === "projects") {
                    for (let key in rowdata) {
                        delete rowdata[key]
                    }
                    rowdata["S.No"] = { value: index + 1 }
                    rowdata["Charger Name"] = { value: data?.charger_name }
                    rowdata["Charger SKU"] = { value: data?.charger_sku }
                    rowdata["Charger Type"] = { value: data?.charger_type ?? "-" }
                    rowdata["Operational Power Rating (kWh)"] = { value: data?.operational_power_rating }
                    rowdata["Serial Number/CIN"] = { value: data?.serial_cin }
                    rowdata["Branding SKU"] = {
                        value: `${data?.branding || ""}${data?.branding && data?.branding_sku ? ", " : ""}${
                            data?.branding_sku || ""
                        }`,
                    }
                    rowdata["Sim Information"] = {
                        value: `${data?.is_sim_required ? "Yes" : "No"}${
                            data?.is_sim_required && data?.sim_uuid ? ", " : ""
                        }${data?.sim_uuid || ""}`,
                    }
                    rowdata["Charging Price"] = { value: data?.charging_price }
                    rowdata["Insurance Required"] = {
                        value: data?.insurance_required === 1 ? "Yes" : data?.insurance_required === 0 ? "No" : "-",
                    }
                    rowdata["Warranty Duration"] = { value: data?.warranty_duration ? data?.warranty_duration : "-" }
                    rowdata["Warranty Comments"] = { value: data?.warranty_comment ? data?.warranty_comment : "-" }
                    rowdata["AMC Purchased"] = { value: data?.amc_purchased ? "Yes" : "No" }
                    rowdata["AMC Status"] = {
                        value: data?.amc_status === 1 ? "Active" : data?.amc_status === 0 ? "Expired" : "Inactive",
                    }
                    rowdata["Service Comments"] = { value: data?.service_comment ? data?.service_comment : "-" }

                    delete rowdataEditable?.["Branding"]
                    delete rowdataEditable?.["Make"]
                    delete rowdataEditable?.["No. Of Connectors"]
                    delete rowdataEditable?.["Type Of Connectors"]
                    delete rowdataEditable?.["Power Rating"]
                    delete rowdataEditable?.["OCPP ID"]
                    delete rowdataEditable?.["Date Of Onboarding"]
                    delete rowdataEditable?.["Charging Price"]
                    delete rowdataEditable?.["Operational Power Rating (kWh)"]
                    delete rowdataEditable?.["Insurance Status"]

                    const headData = [
                        {
                            title: "S.No",
                        },
                        {
                            title: "Charger Name",
                        },
                        {
                            title: "Charger SKU",
                        },
                        {
                            title: "Charger Type",
                        },
                        {
                            title: "Operational Power Rating (kWh)",
                        },
                        {
                            title: "Serial Number/CIN",
                        },
                        {
                            title: "Branding SKU",
                        },
                        {
                            title: "Sim Information",
                        },
                        {
                            title: "Charging Price",
                        },
                        {
                            title: "Insurance Required",
                        },
                        {
                            title: "Warranty Duration",
                        },
                        {
                            title: "Warranty Comments",
                        },
                        {
                            title: "AMC Purchased",
                        },
                        {
                            title: "AMC Status",
                        },
                        {
                            title: "Service Comments",
                        },
                    ]
                    setHeaderData(headData)
                }
                if (ticketData?.type === "installation") {
                    delete rowdata?.["Make"]
                    delete rowdata?.["No. Of Connectors"]
                    delete rowdata?.["Type Of Connectors"]
                    delete rowdata?.["Power Rating"]
                    delete rowdata?.["Date Of Onboarding"]
                    delete rowdata?.["OEM"]
                    delete rowdata?.["Service Scope"]
                    delete rowdata?.["Insurance Procured"]
                    delete rowdata?.["Insurance Required"]
                    delete rowdata?.["AMC Information"]
                    delete rowdata?.["OCPP ID"]
                    delete rowdata?.["Branding"]
                    delete rowdata?.["Sim Information"]
                    delete rowdata?.["Charging Price"]
                    delete rowdata?.["Warranty Duration"]
                    delete rowdata?.["Warranty Comments"]
                    delete rowdata?.["Service Comments"]
                    delete rowdata?.["AMC Purchased"]
                    delete rowdata?.["AMC Status"]
                    delete rowdata?.["Insurance Status"]

                    delete rowdataEditable?.["Make"]
                    delete rowdataEditable?.["No. Of Connectors"]
                    delete rowdataEditable?.["Type Of Connectors"]
                    delete rowdataEditable?.["Power Rating"]
                    delete rowdataEditable?.["Date Of Onboarding"]
                    delete rowdataEditable?.["OEM"]
                    delete rowdataEditable?.["Service Scope"]
                    delete rowdataEditable?.["Insurance Procured"]
                    delete rowdataEditable?.["Insurance Required"]
                    delete rowdataEditable?.["AMC Information"]
                    delete rowdataEditable?.["OCPP ID"]
                    delete rowdataEditable?.["Branding"]
                    delete rowdataEditable?.["Sim Information"]
                    delete rowdataEditable?.["Charging Price"]
                    delete rowdataEditable?.["Warranty Duration"]
                    delete rowdataEditable?.["Warranty Comments"]
                    delete rowdataEditable?.["Service Comments"]
                    delete rowdataEditable?.["AMC Purchased"]
                    delete rowdataEditable?.["AMC Status"]
                    delete rowdataEditable?.["Insurance Status"]
                    delete rowdataEditable?.["Onboarding Status"]

                    if (selectedTab === 1) {
                        delete rowdata?.["Operational Power Rating (kWh)"]
                        delete rowdata?.["Serial Number/CIN"]
                        delete rowdata?.["Charger SKU"]
                        delete rowdata?.["Onboarding Status"]
                        delete rowdata?.["Testing Status"]
                        delete rowdata?.["Commissioning Status"]
                        delete rowdataEditable?.["Operational Power Rating (kWh)"]
                        delete rowdataEditable?.["Serial Number/CIN"]
                        delete rowdataEditable?.["Charger SKU"]
                        delete rowdataEditable?.["Onboarding Status"]
                        delete rowdataEditable?.["Testing Status"]
                        delete rowdataEditable?.["Commissioning Status"]
                        rowdata["Port No."] = { value: data?.port_number ?? "-" }
                        rowdata["URL"] = { value: data?.cms_url ?? "-" }
                        rowdata["OCPP ID"] = {
                            value: data?.ocpp_id ?? "-",
                        }
                        rowdata["Status"] = {
                            value: (
                                <div
                                    style={{
                                        color: data?.charger_status?.color,
                                    }}>
                                    {data?.charger_status?.status}
                                </div>
                            ),
                        }
                        rowdata[""] = {
                            value: (
                                <input
                                    type="checkbox"
                                    defaultChecked={data.commissioning_status === 1}
                                    onChange={e =>
                                        chargerDataSetter(
                                            index,
                                            "commissioning_status",
                                            e.target.checked
                                                ? 1
                                                : chargerDetailsData?.[index].commissioning_status === 1
                                                ? 0
                                                : chargerDetailsData?.[index].commissioning_status
                                        )
                                    }
                                    style={{ marginRight: 3, marginBottom: 3, cursor: "pointer" }}
                                    disabled={true}
                                />
                            ),
                        }

                        rowdataEditable["Port No."] = { value: data?.port_number ?? "-" }
                        rowdataEditable["URL"] = { value: data?.cms_url ?? "-" }
                        rowdataEditable["OCPP ID"] = {
                            value: (
                                <span
                                    style={{
                                        fontWeight: data.commissioning_status === 1 ? 500 : 400,
                                    }}>
                                    {data?.ocpp_id ?? "-"}
                                </span>
                            ),
                        }
                        rowdataEditable["Status"] = {
                            value: (
                                <div
                                    style={{
                                        color: data?.charger_status?.color,
                                        fontWeight: data.commissioning_status === 1 ? 500 : 400,
                                    }}>
                                    {data?.charger_status?.status}
                                </div>
                            ),
                        }
                        rowdataEditable[""] = {
                            value: (
                                <input
                                    type="checkbox"
                                    defaultChecked={data.commissioning_status === 1}
                                    onChange={e =>
                                        chargerDataSetter(
                                            index,
                                            "commissioning_status",
                                            e.target.checked
                                                ? 1
                                                : chargerDetailsData?.[index].commissioning_status === 1
                                                ? 0
                                                : chargerDetailsData?.[index].commissioning_status
                                        )
                                    }
                                    style={{ marginRight: 3, marginBottom: 3, cursor: "pointer" }}
                                    disabled={data?.freeze_commissioning}
                                />
                            ),
                        }

                        for (let key in rowdataEditable) {
                            if (
                                typeof rowdataEditable[key]?.value === "string" ||
                                typeof rowdataEditable[key]?.value === "number"
                            ) {
                                rowdataEditable[key] = {
                                    value: (
                                        <span
                                            style={{
                                                fontWeight: data.commissioning_status === 1 ? 500 : 400,
                                            }}>
                                            {rowdataEditable[key].value}
                                        </span>
                                    ),
                                }
                            }
                        }
                    }
                    const primaryTab = [
                        {
                            title: "S.No",
                        },
                        {
                            title: "Charger Name",
                        },
                        {
                            title: "Charger Type",
                        },
                        {
                            title: "Operational Power Rating (kWh)",
                        },
                        {
                            title: "Serial Number/CIN",
                        },
                        {
                            title: "Charger SKU",
                        },

                        {
                            title: "Onboarding Status",
                        },
                    ]

                    const commissioningTab = [
                        {
                            title: "S.No",
                        },
                        {
                            title: "Charger Name",
                        },
                        {
                            title: "Charger Type",
                        },
                        {
                            title: "Port No.",
                        },
                        {
                            title: "URL",
                        },
                        {
                            title: "OCPP ID",
                        },
                        {
                            title: "Status",
                            minWidth: "110px"
                        },
                        {
                            title: "",
                            maxWidth: "20px",
                            minWidth: "20px",
                        },
                    ]
                    setHeaderData(selectedTab === 1 ? commissioningTab : primaryTab)
                }
                if (ticketData?.type === "finance") {
                    delete rowdata?.["Make"]
                    delete rowdata?.["Power Rating"]
                    delete rowdata?.["OCPP ID"]
                    delete rowdata?.["Date Of Onboarding"]
                    delete rowdata?.["Service Comments"]
                    delete rowdata?.["Operational Power Rating (kWh)"]
                    delete rowdata?.["Insurance Status"]

                    delete rowdataEditable?.["Service Comments"]
                    delete rowdataEditable?.["Make"]
                    delete rowdataEditable?.["Power Rating"]
                    delete rowdataEditable?.["OCPP ID"]
                    delete rowdataEditable?.["Date Of Onboarding"]
                    delete rowdataEditable?.["Operational Power Rating (kWh)"]
                    delete rowdataEditable?.["Insurance Status"]

                    const headData = [
                        {
                            title: "S.No",
                        },
                        {
                            title: "Charger Name",
                        },
                        {
                            title: "Charger Type",
                        },
                        {
                            title: "Serial Number/CIN",
                        },
                        {
                            title: "Charger SKU",
                        },
                        {
                            title: "Branding",
                        },
                        {
                            title: "OEM",
                        },
                        {
                            title: "Type Of Connectors",
                        },
                        {
                            title: "No. Of Connectors",
                        },
                        {
                            title: "Sim Information",
                        },
                        {
                            title: "Charging Price",
                        },
                        {
                            title: "Insurance Required",
                        },
                        {
                            title: "Insurance Procured",
                        },
                    ]
                    setHeaderData(headData)
                }
                if (ticketData?.type === "logistics") {
                    delete rowdata?.["Insurance Required"]
                    delete rowdata?.["AMC Information"]
                    delete rowdata?.["Warranty Status"]
                    delete rowdata?.["Service Scope"]
                    delete rowdata?.["Power Rating"]
                    delete rowdata?.["OCPP ID"]
                    delete rowdata?.["Date Of Onboarding"]
                    delete rowdata?.["Service Comments"]
                    delete rowdata?.["Make"]
                    delete rowdata?.["Operational Power Rating (kWh)"]
                    delete rowdata?.["Insurance Procured"]

                    delete rowdataEditable?.["Service Comments"]
                    delete rowdataEditable?.["Insurance Required"]
                    delete rowdataEditable?.["AMC Information"]
                    delete rowdataEditable?.["Warranty Status"]
                    delete rowdataEditable?.["Service Scope"]
                    delete rowdataEditable?.["Power Rating"]
                    delete rowdataEditable?.["OCPP ID"]
                    delete rowdataEditable?.["Date Of Onboarding"]
                    delete rowdataEditable?.["Make"]
                    delete rowdataEditable?.["Operational Power Rating (kWh)"]
                    delete rowdataEditable?.["Insurance Procured"]

                    const headData = [
                        {
                            title: "S.No",
                        },
                        {
                            title: "Charger Name",
                        },
                        {
                            title: "Charger Type",
                        },
                        {
                            title: "Serial Number/CIN",
                        },
                        {
                            title: "Charger SKU",
                        },
                        {
                            title: "Branding",
                        },
                        {
                            title: "OEM",
                        },
                        {
                            title: "Type Of Connectors",
                        },
                        {
                            title: "No. Of Connectors",
                        },
                        {
                            title: "Sim Information",
                        },
                        {
                            title: "Charging Price",
                        },
                        {
                            title: "Insurance Status",
                        },
                        {
                            title: "Warranty Duration",
                        },
                        {
                            title: "Warranty Comments",
                        },
                        {
                            title: "AMC Purchased",
                        },
                        {
                            title: "AMC Status",
                        },
                    ]
                    setHeaderData(headData)
                }
                if (ticketData?.type === "tech_support") {
                    delete rowdata?.["Operational Power Rating (kWh)"]
                    delete rowdata?.["Sim Information"]
                    delete rowdata?.["Insurance Required"]
                    delete rowdata?.["Insurance Procured"]
                    delete rowdata?.["Warranty Comments"]
                    delete rowdata?.["AMC Purchased"]
                    delete rowdata?.["AMC Status"]
                    delete rowdata?.["Warranty Duration"]
                    delete rowdata?.["AMC Information"]
                    delete rowdata?.["Warranty Status"]
                    delete rowdata?.["Service Scope"]
                    delete rowdata?.["Branding"]
                    delete rowdata?.["Make"]
                    delete rowdata?.["No. Of Connectors"]
                    delete rowdata?.["Service Comments"]
                    delete rowdata?.["Insurance Status"]
                    delete rowdata?.["Onboarding Status"]
                    delete rowdata?.["Commissioning Status"]
                    delete rowdata?.["Testing Status"]

                    delete rowdataEditable?.["Operational Power Rating (kWh)"]
                    delete rowdataEditable?.["Sim Information"]
                    delete rowdataEditable?.["Insurance Required"]
                    delete rowdataEditable?.["Insurance Procured"]
                    delete rowdataEditable?.["Warranty Comments"]
                    delete rowdataEditable?.["AMC Purchased"]
                    delete rowdataEditable?.["AMC Status"]
                    delete rowdataEditable?.["Warranty Duration"]
                    delete rowdataEditable?.["AMC Information"]
                    delete rowdataEditable?.["Warranty Status"]
                    delete rowdataEditable?.["Service Scope"]
                    delete rowdataEditable?.["Branding"]
                    delete rowdataEditable?.["Make"]
                    delete rowdataEditable?.["No. Of Connectors"]
                    delete rowdataEditable?.["Service Comments"]
                    delete rowdataEditable?.["Insurance Status"]
                    delete rowdataEditable?.["Onboarding Status"]
                    delete rowdataEditable?.["Commissioning Status"]
                    delete rowdataEditable?.["Testing Status"]

                    rowdata["Status"] = {
                        value: <div style={{ color: data?.charger_status?.color }}>{data?.charger_status?.status}</div>,
                    }
                    rowdata[""] = {
                        value: (
                            <input
                                type="checkbox"
                                value={data.testing_status}
                                checked={data.testing_status}
                                onChange={e => chargerDataSetter(index, "testing_status", e.target.checked)}
                                style={{ marginRight: 3, marginBottom: 3, cursor: "pointer" }}
                                disabled={true}
                            />
                        ),
                    }
                    rowdataEditable["Status"] = {
                        value: <div style={{ color: data?.charger_status?.color, fontWeight: data.testing_status ? 500 : 400 }}>{data?.charger_status?.status}</div>,
                    }
                    rowdataEditable[""] = {
                        value: (
                            <input
                                type="checkbox"
                                value={data.testing_status}
                                checked={data.testing_status}
                                onChange={e => chargerDataSetter(index, "testing_status", e.target.checked)}
                                style={{ marginRight: 3, marginBottom: 3, cursor: "pointer" }}
                                disabled={data?.freeze_testing_status}
                            />
                        ),
                    }
                    for (let key in rowdataEditable) {
                        if (typeof rowdataEditable[key]?.value === "string" || typeof rowdataEditable[key]?.value === "number") {
                            rowdataEditable[key] = {
                                value: (
                                    <span
                                        style={{
                                            fontWeight: data.testing_status ? 500 : 400,
                                        }}>
                                        {rowdataEditable[key].value}
                                    </span>
                                ),
                            }
                        }
                    }

                    const headData = [
                        {
                            title: "S.No",
                        },
                        {
                            title: "Charger Name",
                        },
                        {
                            title: "Charger Type",
                        },
                        {
                            title: "Serial Number/CIN",
                        },
                        {
                            title: "Charger SKU",
                        },
                        {
                            title: "OEM",
                        },
                        {
                            title: "Type Of Connectors",
                        },
                        {
                            title: "Charging Price",
                        },
                        {
                            title: "Power Rating",
                        },
                        {
                            title: "OCPP ID",
                        },
                        {
                            title: "Date Of Onboarding",
                        },
                        {
                            title: "Status",
                            minWidth: "110px"
                        },
                        {
                            title: "",
                        },
                    ]
                    setHeaderData(headData)
                }
                if (ticketData?.type === "network_reliability") {
                    delete rowdata?.["Branding"]
                    delete rowdata?.["Make"]
                    delete rowdata?.["No. Of Connectors"]
                    delete rowdata?.["Type Of Connectors"]
                    delete rowdata?.["OCPP ID"]
                    delete rowdata?.["Date Of Onboarding"]
                    delete rowdata?.["Power Rating"]
                    delete rowdata?.["Serial Number/CIN"]
                    delete rowdata?.["Insurance Status"]

                    delete rowdataEditable?.["Branding"]
                    delete rowdataEditable?.["Make"]
                    delete rowdataEditable?.["No. Of Connectors"]
                    delete rowdataEditable?.["Type Of Connectors"]
                    delete rowdataEditable?.["OCPP ID"]
                    delete rowdataEditable?.["Date Of Onboarding"]
                    delete rowdataEditable?.["Power Rating"]
                    delete rowdataEditable?.["Serial Number/CIN"]
                    delete rowdataEditable?.["Operational Power Rating (kWh)"]
                    delete rowdataEditable?.["Insurance Status"]

                    const headData = [
                        {
                            title: "S.No",
                        },
                        {
                            title: "Charger Name",
                        },
                        {
                            title: "Charger Type",
                        },
                        {
                            title: "OEM",
                        },
                        {
                            title: "Charging Price",
                        },
                        {
                            title: "Insurance Required",
                        },
                        {
                            title: "Warranty Duration",
                        },
                        {
                            title: "Warranty Status",
                        },
                        {
                            title: "AMC Information",
                        },
                        {
                            title: "Service Scope",
                        },
                        {
                            title: "Service Comments",
                        },
                    ]
                    setHeaderData(headData)
                }
                if (ticketData?.type === "demolitions") {
                    delete rowdata?.["Branding"]
                    delete rowdata?.["Make"]
                    delete rowdata?.["No. Of Connectors"]
                    delete rowdata?.["Type Of Connectors"]
                    delete rowdata?.["OCPP ID"]
                    delete rowdata?.["Date Of Onboarding"]
                    delete rowdata?.["Power Rating"]
                    delete rowdata?.["Charging Price"]
                    delete rowdata?.["Date Of Onboarding"]
                    delete rowdata?.["Insurance Required"]
                    delete rowdata?.["Warranty Duration"]
                    delete rowdata?.["Warranty Status"]
                    delete rowdata?.["AMC Information"]
                    delete rowdata?.["Service Scope"]
                    delete rowdata?.["Service Comments"]
                    delete rowdata?.["Insurance Status"]

                    delete rowdataEditable?.["Branding"]
                    delete rowdataEditable?.["Make"]
                    delete rowdataEditable?.["No. Of Connectors"]
                    delete rowdataEditable?.["Type Of Connectors"]
                    delete rowdataEditable?.["OCPP ID"]
                    delete rowdataEditable?.["Date Of Onboarding"]
                    delete rowdataEditable?.["Power Rating"]
                    delete rowdataEditable?.["Charging Price"]
                    delete rowdataEditable?.["Date Of Onboarding"]
                    delete rowdataEditable?.["Insurance Required"]
                    delete rowdataEditable?.["Warranty Duration"]
                    delete rowdataEditable?.["Warranty Status"]
                    delete rowdataEditable?.["AMC Information"]
                    delete rowdataEditable?.["Service Scope"]
                    delete rowdataEditable?.["Service Comments"]
                    delete rowdataEditable?.["Operational Power Rating (kWh)"]
                    delete rowdataEditable?.["Insurance Status"]

                    const headData = [
                        {
                            title: "S.No",
                        },
                        {
                            title: "Charger Name",
                        },
                        {
                            title: "Charger Type",
                        },
                        {
                            title: "Serial Number/CIN",
                        },
                        {
                            title: "OEM",
                        },
                    ]
                    setHeaderData(headData)
                }
                return { rowdata, rowdataEditable }
            })
            if (Array.isArray(rowData)) {
                setRowData(rowData)
            } else {
                setRowData([])
            }
        }
    }, [showError, selectedTab, isSuccess, openModal, chargerData])

    const disableSave = () => {
        let flag = false
        if (ticketData?.type === "logistics" || ticketData?.type === "finance") {
            for (let i = 0; i < chargerData?.length; i++) {
                if (!chargerData?.[i]?.serial_cin) {
                    flag = true
                    break
                }
            }
        }
        return flag
    }

    const ticketDataSetter = (id: any, type: any) => {
        const clone: any = { ...ticketData }
        clone.id = id
        clone.type = type
        setTicketData(clone)
    }

    const formDataSetter = (arg: any) => {
        setFormData(arg)
    }
    const ticketPutRequiredSetter = (value: boolean) => {
        setTicketPutRequired(value)
    }
    const dispatch = useDispatch()
    const { user_id } = useSelector((store: any) => store.verifyLogin)
    const submitHandler = () => {
        if (!disableSave()) {
            if (ticketData?.type === "logistics") {
                const data = {
                    ticket_id: ticketData?.id,
                    ticket_type: ticketData?.type,
                    data: chargerData?.length
                        ? chargerData?.map((charger: any) => {
                              return {
                                  charger_id: charger?.charger_id,
                                  serial_cin: charger?.serial_cin,
                                  make_id: makeData?.find((data: any) => data?.name === charger?.make)
                                      ? makeData?.find((data: any) => data?.name === charger?.make)?.id
                                      : "",
                              }
                          })
                        : [],
                }
                updateChargerDeatils({ data })
                if (ticketputRequired) {
                    editLogisitcsTicketStatus(formData)
                }
            } else if (ticketData?.type === "finance") {
                const data = {
                    ticket_id: ticketData?.id,
                    ticket_type: ticketData?.type,
                    data: chargerData?.length
                        ? chargerData?.map((charger: any) => {
                              return {
                                  charger_id: charger?.charger_id,
                                  serial_cin: charger?.serial_cin,
                                  insurance_procured: charger?.insurance_procured,
                              }
                          })
                        : [],
                }
                updateChargerDeatils({ data })
                if (ticketputRequired) {
                    editLogisitcsTicketStatus(formData)
                }
            } else if (ticketData?.type === "network_reliability") {
                let flag = false
                for (let i = 0; i < chargerData?.length; i++) {
                    if (chargerData?.[i]?.service_comment) {
                        flag = true
                        break
                    }
                }
                if (!flag) {
                    setOpenModal(false)
                    setServiceCommentsWarningOpen(true)
                } else {
                    const formDatas = new FormData()
                    formDatas.append("status_id", formData?.status_id)
                    formDatas.append("ticket_id", ticketData?.id)
                    formDatas.append("user_id", user_id)
                    formDatas.append("vendor_id", selectedVendor?.id)
                    formDatas.append(
                        "charger_data",
                        JSON.stringify(
                            chargerData?.length
                                ? chargerData?.map((charger: any) => {
                                      return {
                                          charger_id: charger?.charger_id,
                                          service_comment: charger?.service_comment,
                                      }
                                  })
                                : []
                        )
                    )
                    dispatch(editTicketStatusNetworkReliability({ data: formDatas }))
                }
            } else if (ticketData?.type === "installation") {
                const data = {
                    ticket_id: ticketData?.id,
                    ticket_type: ticketData?.type,
                    data: chargerData?.length
                        ? chargerData?.map((charger: any) => {
                              return {
                                  charger_id: charger?.charger_id,
                                  commissioning_status: charger?.commissioning_status,
                              }
                          })
                        : [],
                }
                updateChargerDeatils({ data })
                if (ticketputRequired) {
                    editLogisitcsTicketStatus(formData)
                }
            } else if (ticketData?.type === "tech_support") {
                const data = {
                    ticket_id: ticketData?.id,
                    ticket_type: ticketData?.type,
                    data: chargerData?.length
                        ? chargerData?.map((charger: any) => {
                              return {
                                  charger_id: charger?.charger_id,
                                  testing_status: charger?.testing_status,
                              }
                          })
                        : [],
                }
                updateChargerDeatils({ data })
                setChargerTested(true)
            }
        }
    }

    return (
        <chargerDetailsContext.Provider
            value={{
                allowEdit:
                    (ticketData?.type === "logistics" &&
                        deal_type?.toLowerCase() === "cpo" &&
                        (ticket_status?.toLowerCase() === "open" ||
                            ticket_status?.toLowerCase() === "dispatch initiated")) ||
                    ticketData?.type === "network_reliability" ||
                    ticketData?.type === "finance" ||
                    ticketData?.type === "tech_support" ||
                    (ticketData?.type === "installation" && selectedTab === 1),
                openModal,
                setOpenModal,
                headerData: headerData.map((header: { title: string }) =>
                    header.title === "S.No" || header.title === ""
                        ? header
                        : {
                            minWidth:
                            header.title === "Charger Type" || header.title === "Charger Name" ? "120px" : "93px",
                            ...header,
                          }
                ),
                rowData: editable
                    ? rowData?.map((data: any) => data?.rowdataEditable)
                    : rowData?.map((data: any) => data?.rowdata),
                editable,
                setEditable,
                disableSave,
                submitHandler,
                ticketDataSetter,
                formDataSetter,
                resetStates,
                ticketPutRequiredSetter,
                setSelectedTab,
                selectedTab,
                tabRef,
                ticketData,
                chargerTested,
                serviceCommentsWarningOpen,
                setServiceCommentsWarningOpen,
                selectedVendor,
                setSelectedVendor,
                setShowError,
                getHeading,
            }}>
            {children}
        </chargerDetailsContext.Provider>
    )
}

export const useChargerDetails = () => {
    const value: any = useContext(chargerDetailsContext)
    return { value }
}

export default ChargerDetailsProvider
